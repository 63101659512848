import { Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import AWS from "aws-sdk";
import { useState, useRef } from "react";
import { v4 as uuidv4 } from 'uuid'; // Import uuid

const UploadProfilePicture = ({ value, onChange }) => {
  const S3_BUCKET = "stella-icons";
  const REGION = "us-east-2";

  // Configure AWS SDK
  AWS.config.update({
    accessKeyId:import.meta.env.VITE_ACCESS_KEY_ID ,
    secretAccessKey: import.meta.env.VITE_SECRET_ACCESS_KEY,
  });

  const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const [imageURL, setImageURL] = useState(value ? value : ""); // Set initial URL
  const requestCountRef = useRef(0); // Ref to track request count
  const resetTimeoutRef = useRef(null); // Ref to hold the reset timeout

  const uploadFile = async (file) => {
    const uniqueFileName = `${uuidv4()}_${file.name}`; // Use uuid for unique filename
    console.log("hhhhh",file)
    const params = {
      Bucket: S3_BUCKET,
      Key: `src/${uniqueFileName}`,
      Body: file,
      ContentType: file.type,
    };
    try {
      const data = await s3.upload(params).promise();
      console.log("File uploaded successfully:", data.Location);
      return data.Location; // Return only the URL
    } catch (error) {
      console.error("Error in uploadFile:", error);
      Swal.fire({
        icon: "error",
        title: "Upload failed",
        text: "File upload failed. Please try again.",
      });
      return null;
    }
  };

  const handleImageChange = async ({ file }) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You can only upload JPG/PNG file!",
      });
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Image must be smaller than 2MB!",
      });
      return;
    }

    if (requestCountRef.current < 10) {
      requestCountRef.current++;
      const uploadedImageURL = await uploadFile(file.originFileObj || file);
      if (uploadedImageURL) {
        setImageURL(uploadedImageURL);
        onChange(uploadedImageURL); // Change this to the expected format
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "Request limit reached",
        text: "You can only upload a maximum of 10 images every 40 seconds. Please try again later.",
      });
      return;
    }

    // Reset the count after 40 seconds
    if (!resetTimeoutRef.current) {
      resetTimeoutRef.current = setTimeout(() => {
        requestCountRef.current = 0;
        resetTimeoutRef.current = null; // Clear the timeout ref
      }, 40000); // 40,000 ms = 40 seconds
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Upload
      name="avatar"
      listType="picture-circle"
      className="avatar-uploader"
      showUploadList={false}
      beforeUpload={() => false}
      onChange={handleImageChange}
    >
      {imageURL ? (
        <img
          src={imageURL}
          alt="avatar"
          style={{
            width: "100%",
            borderRadius: "50%",
          }}
        />
      ) : (
        uploadButton
      )}
    </Upload>
  );
};

export default UploadProfilePicture;

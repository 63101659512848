import { useNavigate } from "react-router-dom";
import axios from "axios";
import { GetFcmToken } from "../firebase/firebase";
import { useEffect, useState } from "react";

const Logout = () => {
  const [FCMToken, setFCMToken] = useState("");
  const navigate = useNavigate();

  useEffect(()=>{
    GetFcmToken().then(token => setFCMToken(token))
     .catch(error => console.error("Failed to retrieve FCM token:", error));
  })
  const handleLogout = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    // console.log("user",user.data.userProfile.id)
    if (user?.data?.accessToken) {
      try {
        const url = `${import.meta.env.VITE_APP_BASE_API_URL}/users/fcmDestroy`;
        const headers = {
          Authorization: `Bearer ${user.data.accessToken}`,
        };
        await axios.post(url, {
          fcmToken:FCMToken
        }, { headers });
      } catch (error) {
        console.error("Failed to destroy FCM session during logout:", error);
      }
    }

    // Clear localStorage and navigate to login
    localStorage.removeItem("user");
    localStorage.removeItem("tabs");
    navigate("/login");
  };

  // Trigger the logout on component mount
  handleLogout();

  return null; // No UI is rendered
};

export default Logout;
